import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Iconify from "../iconify";
import {
  Button,
  Checkbox,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  Popover,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  FileUpload,
  deleteData,
  getData,
  patchData,
  postData,
} from "src/webService/webService";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Card,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import { setAuditList } from "src/redux/slices/PlannerSlice";
import { useDispatch, useSelector } from "react-redux";
import CLIENTROUTES from "src/API_Routes/Client/Client_Routers";
import REVIEWERROUTES from "src/API_Routes/Reviewer/Reviewer_Router";
import AUDITROUTES from "src/API_Routes/Audit/Audit_routes";
import EMAIL_ROUTES from "src/API_Routes/Email/Email_Routers";
import ReviewAssignEmail from "src/Config/templates/html/ReviewAssign";
import EVENT_TRACKING_ROUTES from "src/API_Routes/EventTracking/EventTracking_Routers";
import ViewAudit from "./ViewAudit";
import ReviewerAuditFilter from "../Filter/Reviewer/AuditFilter";
import { fetchAllAuditor } from "../../CommonFunction/GetAllAuditor";
import ClientStatusTrackerBasic from "../../CommonFunction/clientStatusTrackerBasic";
import CommonEmail from "../../Config/templates/html/CommonEmailTem";
import AuditFilterAdmin from "../Filter/AuditFilterAdmin";
import { fetchAllPlanner } from "../../CommonFunction/GetAllPlanner";
import { fetchAllSales } from "../../CommonFunction/GetAllSales";
import FILEROUTES from "../../API_Routes/File/File_Router";
import AuditorCalendar from "../../pages/Auditor/Calendar";
import CertificateForm from "../Certificate/CertificateForm";
import { getFormattedIndiaTime } from "../../utils/timeutils";
import EventDialog from "../Events/Events";
import SERVER_URL from "../../utils/serverURL";
import moment from "moment-timezone";

function Audit() {
  const formattedDate = getFormattedIndiaTime();
  const AuditorNameList = useSelector(
    (state) => state?.AuditorList.AuditorNameList
  );
  const PlannerNameList = useSelector(
    (state) => state?.PlannerList.PlannerNameList
  );
  const SalesNameList = useSelector((state) => state?.SalesList.SalesNameList);
  console.log(AuditorNameList, "AuditorNameList");
  const [auditDate, setAuditDate] = useState([]);
  const date = new Date().toLocaleDateString();
  const scheme_session = sessionStorage.getItem("scheme");
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const dispatch = useDispatch();
  const AuditList = useSelector(
    (state) => state?.Planner?.AuditList?.paginatedResults
  );
  console.log(AuditList, "AuditList1234");
  const AuditListArray = useSelector((state) => state?.Planner?.AuditList);
  const UserId = sessionStorage.getItem("UserId");
  const accessToken = sessionStorage.getItem("Token");
  //Get All Client
  const [clientList, setClientList] = useState();
  const [selectedStatus, setSelectedStatus] = useState("");
  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };
  const getclient = async () => {
    try {
      const scheme = scheme_session === "HRA" ? "HRA" : "CB";
      let endpoint;
      if (selectedStatus === "Scope Extension") {
        endpoint = `masters/audit/client/scope-extension?scheme=CB`;
      } else {
        endpoint = `masters/audit/client/companynames?status=${selectedStatus}&scheme=${scheme}`;
      }
      const res = await getData(endpoint, null, accessToken);
      if (res.status === 200) {
        setClientList(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  useEffect(() => {
    if (selectedStatus) {
      getclient();
    }
  }, [selectedStatus]);

  //Assign Client
  const [selectedClient, setSelectedClient] = useState();
  const handleClientChange = (event) => {
    setSelectedClient(event.target.value);
  };

  const [clientBasic, setClientBasic] = useState();
  const [clientAdvance, setClientAdvance] = useState();

  useEffect(() => {
    const fetchClientData = async () => {
      if (selectedClient) {
        try {
          const BasicClient = await getData(
            `clients/client/${selectedClient}/basic`,
            null,
            accessToken
          );
          setClientBasic(BasicClient.data);

          const AdvanceClient = await getData(
            `clients/client/${selectedClient}/advanced`,
            null,
            accessToken
          );
          setClientAdvance(AdvanceClient.data);

          console.log(AdvanceClient.data, "clientAdvance");
        } catch (error) {
          console.error("Error fetching client data:", error);
        }
      }
    };

    fetchClientData();
  }, [selectedClient, accessToken]);

  // Dialog
  const [Uploadopen, setUploadopen] = useState(false);
  const handleClickOpen = () => {
    setUploadopen(true);
  };
  const handleClose = () => {
    setViewAudit(false);
    setUploadopen(false);
    setDate();
    setSelectedAuditor();
    setScheduledAuditorsWithDates([]);
    setauditors();
    setActivityType();
    setSelectedClient();
    setSelectedManDays();
    setauditorDesignation();
    setauditStandards();
    setClientAdvance();
    setClientBasic();
    setAction();
  };

  //Assign Auditor
  const [dateval, setDate] = useState();
  // Function to get today's date in 'YYYY-MM-DD' format
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Add leading zero
    const day = String(today.getDate()).padStart(2, "0"); // Add leading zero
    return `${year}-${month}-${day}`;
  };
  const [selectedAuditor, setSelectedAuditor] = useState("");
  const [scheduledAuditorsWithDates, setScheduledAuditorsWithDates] = useState(
    []
  );
  console.log("scheduledAuditorsWithDates", scheduledAuditorsWithDates);

  const [auditors, setauditors] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getData(
          `masters/audit/auditors/free?mandaytype=${selectedManDays}&date=${dateval}&scheme=CB`,
          null,
          accessToken
        );
        if (res.status === 200) {
          const combinedAuditors = [
            ...res.data.AuditorsFreeOnSpecificDate,
            ...res.data.AuditorsFreeOnSpecificDateAsPerManday,
          ];
          setauditors(combinedAuditors);
          console.log(res);
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("An error occurred. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    };
    if (dateval) {
      fetchData();
    }
  }, [dateval]);
  const handleAuditorChange = async (event) => {
    setSelectedAuditor(event.target.value);
    const res1 = await getData(
      `users/basic/user/${event.target.value}`,
      null,
      accessToken
    );
    setAuditDate(res1.data.auditDates);
  };
  const [params, setParams] = useState({
    auditDates: [],
  });
  const handleAddAuditor = () => {
    debugger;
    if (selectedAuditor && dateval) {
      // Check if the selected auditor is already assigned
      // const isAuditorAssigned = scheduledAuditorsWithDates.some(
      //   (auditor) => auditor.auditorId === selectedAuditor
      // );

      // if (!isAuditorAssigned) {
      // Update auditDate state first
      const updatedAuditDate = [
        ...auditDate,
        { date: dateval, manDayType: selectedManDays },
      ];
      setAuditDate(updatedAuditDate);
      const updatedParams = {
        ...params,
        auditDates: updatedAuditDate,
      };
      setParams(updatedParams);
      const res2 = patchData(
        `users/basic/user/${selectedAuditor}`,
        updatedParams,
        accessToken,
        dispatch
      );
      const updatedAuditorwithDates = [
        ...scheduledAuditorsWithDates,
        {
          auditorId: selectedAuditor,
          auditorDesignation: auditorDesignation,
          scheduledDate: dateval,
        },
      ];
      setScheduledAuditorsWithDates(updatedAuditorwithDates);
      const plannerid = isAuthenticated === "Admin" ? null : UserId;
      const params_1 = {
        clientId: selectedClient,
        auditType: activityType,
        auditStandards: auditStandards,
        scheme: clientBasic.standardsSchemeType,
        scheduledAuditorsWithDates: updatedAuditorwithDates,
        plannerId: plannerid,
      };
      if (action === "Edit") {
        patchData(
          `audit/${getSpecificclientid._id}/schedule`,
          params_1,
          accessToken,
          dispatch
        );
      }
      // Clear the selected auditor and date after adding
      setSelectedAuditor("");
      setauditorDesignation();
      setDate("");
      setauditStandards();
      setSelectedManDays();
      // }
      // else {
      // toast.error("Auditor is already assigned.", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      // Clear the selected auditor and date after adding
      // setSelectedAuditor("");
      // setauditorDesignation("");
      // setDate("");
      // setauditStandards();
      // setSelectedManDays();
      // Auditor is already assigned, show an error message or handle accordingly
      // }
    }
    setauditorDesignation();
  };

  const CancelAuditor = () => {
    setDate();
    setauditorDesignation();
    setauditStandards();
    setSelectedAuditor("");
    setSelectedManDays();
  };

  const handleRemoveAuditor = (index) => {
    setScheduledAuditorsWithDates((prevScheduledAuditorsWithDates) =>
      prevScheduledAuditorsWithDates.filter((_, i) => i !== index)
    );
  };
  const openFileUploadDialog = () => {
    return new Promise((resolve) => {
      const input = document.createElement("input");
      input.type = "file";
      input.onchange = () => {
        const file = input.files[0];
        resolve(file);
      };
      input.click();
    });
  };
  const updateClientStatus = async () => {
    // ClientStatusTrackerBasic(
    //   selectedClient,
    //   "0",
    //   `${activityType} - Auditor Assigned`,
    //   clientBasic.clientStatusTracker
    // );
    const newStatusTracking = {
      year: 0,
      status: `${activityType} - Auditor Assigned`,
      date: formattedDate,
    };
    const updatedClientStatusTracker = [
      ...clientBasic.clientStatusTracker,
      newStatusTracking,
    ];
    const params = {
      clientStatusTracker: updatedClientStatusTracker,
    };
    await patchData(
      `clients/client/${selectedClient}/basic`,
      params,
      accessToken,
      dispatch
    );
  };
  const scheduleAuditor = async () => {
    const plannerid = isAuthenticated === "Admin" ? null : UserId;
    const params = {
      clientId: selectedClient,
      auditType: activityType,
      auditStandards: auditStandards,
      scheme: clientBasic.standardsSchemeType,
      scheduledAuditorsWithDates: scheduledAuditorsWithDates,
      plannerId: plannerid,
    };
    let res;
    if (action === "Edit") {
      res = await patchData(
        `audit/${getSpecificclientid._id}/schedule`,
        params,
        accessToken,
        dispatch
      );
    } else {
      const HTML_DATA = {
        ClientName: clientBasic.companyName,
        ContactPerson: clientBasic.contactPerson,
        ClientID: clientBasic.clientId,
        Message: "Check the Audit Intimate letter",
      };
      // Create an array to store the filename and path
      const fileInfoArray = [
        {
          filename: uploadResponse.filename,
          path: uploadResponse.path,
        },
      ];
      if (clientBasic.email.includes("@")) {
        const emailRes = await postData(
          EMAIL_ROUTES.SEND_EMAIL_FILE,
          {
            to: clientBasic.email,
            subject: "Audit Intimate letter",
            html: CommonEmail(HTML_DATA),
            attachments: fileInfoArray,
          },
          accessToken,
          dispatch
        );
      }

      res = await postData(`audit/schedule`, params, accessToken, dispatch);
    }
    const auditid = res.data._id;
    const formattedDate1 = getFormattedIndiaTime();
    let Params;
    if (activityType === "Scope Extension") {
      Params = {
        isScopeExtension: "false",
      };
    } else {
      Params = {
        status: {
          type: "auditor assigned",
          updatedDate: formattedDate1,
        },
      };
    }

    const endpoint = `${CLIENTROUTES.GETCLIENTADVANCE}${selectedClient}/basic`;
    await patchData(endpoint, Params, accessToken, dispatch);
    await postData(
      EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(selectedClient),
      {
        description: `${activityType} - Auditor Assigned Successfully`,
        performedBy: `${sessionStorage.getItem(
          "firstName"
        )} ${sessionStorage.getItem("lastName")}`,
      },
      accessToken,
      dispatch
    );
    const status = {
      reportStatus: {
        name: "Auditor Assigned & Audit Intimation Letter Sent",
        date: formattedDate,
      },
      activityStep: "1",
    };
    await patchData(
      `${AUDITROUTES.SCHEDULEPATCH(auditid)}`,
      status,
      accessToken,
      dispatch
    );
    setUploadResponse(null);
    updateClientStatus();
    setUploadopen(false);
    setSelectedClient();
    setScheduledAuditorsWithDates([]);
    setSelectedAuditor("");
    setActivityType();
    setDate();
    setauditorDesignation();
    setauditStandards();
    setClientAdvance();
    setClientBasic();
    AuditResult(currentPage);
    // window.location.reload();
  };

  //GetAuditResult
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);
  const AuditResult = async (page) => {
    const params = {
      page: page,
      limit: limit,
      scheme: scheme_session === "HRA" ? "hra" : "cb",
    };
    try {
      const endpoint =
        scheme_session === "HRA"
          ? `audit/all/subjectmatterexpert/${UserId}`
          : isAuthenticated === "CSO"
          ? `audit/all/cso/${UserId}`
          : isAuthenticated === "Reviewer"
          ? `audit/all/reviewer/${UserId}`
          : isAuthenticated === "Admin"
          ? `audit/all/norole/${UserId}`
          : `audit/all/planner/${UserId}`;
      const res = await getData(endpoint, params, accessToken);
      if (res.status === 200) {
        dispatch(setAuditList(res.data[0]));
        setTotalPages(res?.data[0]?.totalPages);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };

  useEffect(() => {
    AuditResult(currentPage);
  }, [currentPage, limit]);

  //Get Reviewer List
  const [ReviewerList, setReviewerList] = useState();
  const GETReviewer = async () => {
    try {
      const res = await getData(
        REVIEWERROUTES.GETREVIEWERLIST,
        null,
        accessToken
      );
      if (res.status === 200) {
        setReviewerList(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    fetchAllPlanner(dispatch, accessToken);
    fetchAllSales(dispatch, accessToken);
    if (isAuthenticated !== "Reviewer") {
      GETReviewer();
      fetchAllAuditor(dispatch, accessToken);
    }
  }, []);

  const [selectedValues, setSelectedValues] = useState({});
  const [activityType, setActivityType] = useState();
  const [auditorDesignation, setauditorDesignation] = useState();
  const [auditStandards, setauditStandards] = useState();
  const [selectedManDays, setSelectedManDays] = useState("");
  const [getSpecificclientid, setgetSpecificclientid] = useState();
  const [open, setOpen] = useState(null);
  const [action, setAction] = useState("");

  const handleActivityType = (event) => {
    setActivityType(event.target.value);
  };
  const handleauditorDesignation = (event) => {
    setauditorDesignation(event.target.value);
  };
  const handleauditStandards = (event) => {
    setauditStandards(event.target.value);
  };
  const handleChangeManDays = (event) => {
    setSelectedManDays(event.target.value);
  };
  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    const foundClient = AuditList.find((response) => response._id === id);
    if (foundClient) {
      setgetSpecificclientid(foundClient);
    }
  };
  console.log(getSpecificclientid, "getSpecificclientid");
  const handleCloseMenu = () => {
    setOpen(null);
  };

  //assign Reviewer

  const handleChange = async (event, data, Type, clientbasicdata) => {
    event.preventDefault();
    const selectedValue = event.target.value;
    setSelectedValues((prevState) => ({ ...prevState, [data]: selectedValue }));
    const formattedDate = getFormattedIndiaTime();
    const params = {
      reviewerId: selectedValue,
      reportStatus: {
        name: "Technical Report Review Pending",
        date: formattedDate,
      },
      activityStep: "5",
    };
    const endpoint = await patchData(
      `${AUDITROUTES.SCHEDULEPATCH(data)}`,
      params,
      accessToken,
      dispatch
    );
    const reviewerName = findUserById(selectedValue);
    const reviewerfullName = reviewerName.firstName + reviewerName.lastName;
    let year;
    if (
      (clientbasicdata[0].reviewHistory.length === 0 && Type === "STAGE_1") ||
      (clientbasicdata[0].reviewHistory.length === 1 && Type === "STAGE_2") ||
      clientbasicdata[0].reviewHistory.length === 0
    ) {
      year = "1";
    } else if (clientbasicdata[0].reviewHistory.length === 1) {
      year = "2";
    } else {
      year = "3";
    }
    const newReview = {
      year: year,
      reviewerName: reviewerfullName,
      reviewType: Type,
      reviewStatus: "pending",
      reviewAssignedOn: formattedDate,
      reviewDoneOn: "",
    };
    const updateReview = [...clientbasicdata[0].reviewHistory, newReview];
    const params1 = {
      reviewHistory: updateReview,
    };
    await patchData(
      `clients/client/${clientbasicdata[0]._id}/basic`,
      params1,
      accessToken,
      dispatch
    );

    const targetAudit = AuditList.find((audit) => audit._id === data);
    const targetReviewerEmailId = ReviewerList.find(
      (reviewer) => reviewer._id === selectedValue
    );
    await postData(
      EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(
        targetAudit.ClientBasic_Data[0]._id
      ),
      {
        description: `Reviewer assigned for the ${Type} audit report`,
        performedBy: `${sessionStorage.getItem(
          "firstName"
        )} ${sessionStorage.getItem("lastName")}`,
      },
      accessToken,
      dispatch
    );
    if (targetAudit) {
      const HTML_DATA = {
        ClientName: targetAudit.ClientBasic_Data[0].companyName,
        ContactPerson: targetAudit.ClientBasic_Data[0].contactPerson,
        ClientID: targetAudit.ClientBasic_Data[0].clientId,
        AuditType: Type,
        Message:
          "The above client audit reporting completed. Please complete the report review.",
      };
      const emailRes = await postData(
        EMAIL_ROUTES.SEND_EMAIL_TEXT,
        {
          to: targetReviewerEmailId.email,
          subject: "Reviewer Assigned",
          html: CommonEmail(HTML_DATA),
        },
        accessToken,
        dispatch
      );
    } else {
      console.log("Client basic data not found for the specified audit.");
    }
    findUserById(selectedValue);
    AuditResult(currentPage);
    setSelectedValues({});
  };

  // find assigned reviewer
  const findUserById = (id) => {
    if (ReviewerList && id) {
      const user = ReviewerList.find((user) => user._id === id);
      if (user) {
        return { firstName: user.firstName, lastName: user.lastName };
      }
    }
    return { firstName: "-", lastName: "" }; // Return empty values if no match found
  };

  // DeleteAudit
  let Ress2;
  function removeMatchingDates(ress1, Ress2) {
    const scheduledDates = Ress2.map((item) => item.scheduledDate);
    return ress1.filter((item) => !scheduledDates.includes(item.date));
  }
  const handleDelete = async (data) => {
    // Show confirmation dialog
    const confirmDelete = window.confirm("Are you sure you want to delete?");
    if (confirmDelete) {
      Ress2 = data.scheduledAuditorsWithDates || [];
      handleDeleteClick(data);
      const formattedDate = getFormattedIndiaTime();
      const paramsStatus = {
        status: {
          type: "approved",
          updatedDate: formattedDate,
        },
      };
      const clientbasicid = data.ClientBasic_Data[0]._id;
      const basic = await patchData(
        `${CLIENTROUTES.GETCLIENTBASIC}/${clientbasicid}/basic`,
        paramsStatus,
        accessToken,
        dispatch
      );
    }
  };
  const handleDeleteClick = async (data) => {
    if (Ress2.length > 0) {
      const ress1Promises = data.AuditorBasic_Data.map(async (auditor) => {
        const res1Data = await getData(
          `users/basic/user/${auditor._id}`,
          null,
          accessToken
        );
        return res1Data.data.auditDates || [];
      });

      // Wait for all ress1Data to be fetched
      const ress1Data = await Promise.all(ress1Promises);
      // Combine all auditDates into a single array
      const combinedAuditDates = ress1Data.flat();

      const updatedRes1 = removeMatchingDates(combinedAuditDates, Ress2);
      console.log(updatedRes1, "updatedRes1");

      // Array to store all patchData promises
      const patchPromises = [];

      // Loop through AuditorBasic_Data array and update each entry
      for (const auditorData of getSpecificclientid.AuditorBasic_Data) {
        const patchPromise = patchData(
          `users/basic/user/${auditorData._id}`,
          { auditDates: updatedRes1 },
          accessToken,
          dispatch
        );
        patchPromises.push(patchPromise);
      }

      // Wait for all patchData promises to resolve
      await Promise.all(patchPromises);

      // After all patchData is completed, delete res2 data
      await deleteData(
        `audit/${getSpecificclientid._id}/schedule`,
        accessToken
      );
      setOpen(null);
      AuditResult(currentPage);
    }
  };

  const AuditorRemove = async (id, date) => {
    const auditData = await getData(
      `users/basic/user/${id}`,
      null,
      accessToken
    );

    const filteredData = {
      auditDates: auditData.data.auditDates.filter(
        (entry) => entry.date !== date
      ),
    };

    const updatedAuditors = scheduledAuditorsWithDates.filter(
      (auditor) => auditor.auditorId !== id
    );
    setScheduledAuditorsWithDates(updatedAuditors);
    await patchData(
      `users/basic/user/${id}`,
      filteredData,
      accessToken,
      dispatch
    );
    const plannerid = isAuthenticated === "Admin" ? null : UserId;
    const params = {
      clientId: selectedClient,
      auditType: activityType,
      auditStandards: auditStandards,
      scheme: clientBasic.standardsSchemeType,
      scheduledAuditorsWithDates: updatedAuditors,
      plannerId: plannerid,
    };

    if (action === "Edit") {
      await patchData(
        `audit/${getSpecificclientid._id}/schedule`,
        params,
        accessToken,
        dispatch
      );
    }
  };

  //specific Audit
  const [specificAudit, setSpecificAudit] = useState(null);

  const [specificClient, setSpecificClient] = useState();
  useEffect(() => {
    if (specificAudit) {
      const foundClient = AuditList.find(
        (response) => response._id === specificAudit
      );
      setSpecificClient(foundClient);
    }
  }, [specificAudit]);
  useEffect(() => {
    if (selectedValues === "Scope Extension") {
      setActivityType("Scope Extension");
    }
  }, [selectedValues]);

  //view Specific Audit
  const [viewspecificAudit, setViewSpecificAudit] = useState(false);
  const BackToAudit = () => {
    AuditResult(currentPage);
    setViewSpecificAudit(false);
  };

  const [isChecked, setIsChecked] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  // Function to handle checkbox change
  const handleCheckboxChange = (event, id) => {
    setIsChecked({ ...isChecked, [id]: event.target.checked });
  };
  // Function to handle select all checkbox change
  const handleSelectAllCheckboxChange = (event) => {
    const { checked } = event.target;
    const updatedIsChecked = {};

    // Update isChecked object with all checkboxes' states
    AuditList.forEach((row) => {
      updatedIsChecked[row._id] = checked;
    });

    setIsChecked(updatedIsChecked);
    setSelectAllChecked(checked);
  };

  //Filter
  const [filter, SetFilter] = useState(false);
  const ChangeFilter = () => {
    SetFilter(!filter);
  };
  useEffect(() => {
    if (!filter) {
      AuditResult(currentPage);
    }
  }, [filter]);
  // Export data

  // Find the Next Audit
  const stepMapping = {
    STAGE_1: "stage - 1",
    STAGE_2: "stage - 2",
    SA_1: "Surveillance Audit - 1",
    SA_2: "Surveillance Audit - 2",
    RECERTIFICATION: "Re-Certification",
  };
  const stepsMapping = {
    "Initial Certification": ["STAGE_1", "STAGE_2", "SA_1", "SA_2"],
    "Transfer Certification in SA1": ["SA_1", "SA_2"],
    "Transfer Certification in SA2": ["SA_2"],
    "Transfer Certification in Re-Certification": [
      "RECERTIFICATION",
      "SA_1",
      "SA_2",
    ],
    "Re-Certification": ["RECERTIFICATION", "SA_1", "SA_2"],
  };
  const getNextStep = (category, step) => {
    const currentSteps = stepsMapping[category];
    const currentIndex = currentSteps.indexOf(step);

    if (currentIndex === -1 || currentIndex === currentSteps.length - 1) {
      return null;
    }

    return currentSteps[currentIndex + 1];
  };
  const getNextStepValues = (category, step, data) => {
    const activities = data?.auditCertificationActivitiesAndFees;
    if (!activities) return {};

    const nextStep = getNextStep(category, step);
    if (!nextStep) {
      return {
        nextStep: null,
        onSite: null,
        offSite: null,
        message: "This is the final audit.",
      };
    }
    let nextValues = {};
    switch (stepMapping[nextStep]) {
      case "stage - 2":
        nextValues = {
          onSite: activities.stage[1]?.manDay?.onSite,
          offSite: activities.stage[1]?.manDay?.offSite,
        };
        break;
      case "Surveillance Audit - 1":
        if (category === "Re-Certification") {
          nextValues = {
            onSite: activities.surveillanceAudit[1]?.manDay?.onSite,
            offSite: activities.surveillanceAudit[1]?.manDay?.offSite,
          };
        } else {
          nextValues = {
            onSite: activities.surveillanceAudit[0]?.manDay?.onSite,
            offSite: activities.surveillanceAudit[0]?.manDay?.offSite,
          };
        }

        break;
      case "Surveillance Audit - 2":
        if (category === "Re-Certification") {
          nextValues = {
            onSite: activities.surveillanceAudit[2]?.manDay?.onSite,
            offSite: activities.surveillanceAudit[2]?.manDay?.offSite,
          };
        } else {
          nextValues = {
            onSite: activities.surveillanceAudit[1]?.manDay?.onSite,
            offSite: activities.surveillanceAudit[1]?.manDay?.offSite,
          };
        }

        break;
      case "Re-Certification":
        nextValues = {
          onSite: activities.re_Certification[0]?.manDay?.onSite,
          offSite: activities.re_Certification[0]?.manDay?.offSite,
        };
        break;
      default:
        break;
    }
    return { nextStep: nextStep, ...nextValues };
  };
  //Find the Next Audit

  // Function to handle export action

  const handleExport = () => {
    const headers = [
      "Client Id",
      "Company Name",
      "Location",
      "Accredited / Unaccredited",
      "Scope",
      "Scheme",
      "Technical Main codes",
      "Technical Sub codes",
      "No. & name of site",
      "No. of MD",
      "Scheduled by",
      "Current executed Audit Type",
      "Audit scheduling",
      "Next type of Audit / Onsite / OffSite",
      "Order value",
      "Lead generated by",
      "Sales co-ordinator",
      "Contact person details - mail / name & mobile",
      // "Corrective action status",
    ].filter(Boolean);
    // Filter checked items
    const checkedItems = AuditList.filter((item) => isChecked[item._id]);
    let data;
    data = checkedItems.map((item) => {
      const getPlannerPersonNameById = (id, PlannerList) => {
        if (id === null) {
          return { firstName: "Admin", lastName: "CB" };
        } else {
          const person = PlannerList.find(
            (plannerPerson) => plannerPerson._id === id
          );
          if (person) {
            return { firstName: person.firstName, lastName: person.lastName };
          } else {
            return null;
          }
        }
      };
      const PlannerFind = getPlannerPersonNameById(
        item.plannerId,
        PlannerNameList
      );
      const PlannerName = PlannerFind.firstName + PlannerFind.lastName;
      const location =
        item.ClientBasic_Data[0].state + "/" + item.ClientBasic_Data[0].country;
      const reportStatus =
        item.reportStatus.name || item.reportStatus || "Unknown";
      const Accredited =
        item.ClientFormIMS_Data?.[0]?.auditCertificationActivitiesAndFees
          ?.auditTypeStates?.[0]?.name === "Initial Certification" ||
        item.ClientFormFSMS_Data?.[0]?.auditCertificationActivitiesAndFees
          ?.auditTypeStates?.[0]?.name === "Initial Certification"
          ? "Unaccredited"
          : "Accredited";
      const calculateOverallTotalManDays = (item) => {
        let totalManDays = 0;
        let itemScheme = {};

        if (item.ClientFormIMS_Data && item.ClientFormIMS_Data[0]) {
          itemScheme =
            item.ClientFormIMS_Data[0].auditCertificationActivitiesAndFees ||
            {};
        } else if (item.ClientFormFSMS_Data && item.ClientFormFSMS_Data[0]) {
          itemScheme =
            item.ClientFormFSMS_Data[0].auditCertificationActivitiesAndFees ||
            {};
        }

        // Calculate total man-days from surveillance audits
        if (itemScheme.surveillanceAudit) {
          itemScheme.surveillanceAudit.forEach((audit) => {
            if (audit.manDay && audit.manDay.totalManDays) {
              totalManDays += audit.manDay.totalManDays;
            }
          });
        }

        // Calculate total man-days from re-certifications
        if (itemScheme.re_Certification) {
          itemScheme.re_Certification.forEach((certification) => {
            if (certification.manDay && certification.manDay.totalManDays) {
              totalManDays += certification.manDay.totalManDays;
            }
          });
        }

        // Calculate total man-days from transfer certifications
        if (itemScheme.transferCertification) {
          itemScheme.transferCertification.forEach((certification) => {
            if (certification.totalManDays) {
              totalManDays += certification.totalManDays;
            }
          });
        }
        // Check for Initial Certification and add stage 1, stage 2, sa1, and sa2 man-days
        const hasInitialCertification =
          itemScheme.auditTypeStates &&
          itemScheme.auditTypeStates.some(
            (state) => state.name === "Initial Certification"
          );

        if (hasInitialCertification) {
          if (itemScheme.stage) {
            itemScheme.stage.forEach((stage) => {
              if (stage.manDay) {
                totalManDays +=
                  (stage.manDay.onSite || 0) + (stage.manDay.offSite || 0);
              }
            });
          }

          if (itemScheme.surveillanceAudit) {
            itemScheme.surveillanceAudit.forEach((audit) => {
              if (audit.manDay) {
                totalManDays +=
                  (audit.manDay.onSite || 0) + (audit.manDay.offSite || 0);
              }
            });
          }
        }

        return totalManDays;
      };
      const clientCategory =
        item.ClientFormIMS_Data?.[0]?.auditCertificationActivitiesAndFees
          ?.auditTypeStates?.[0]?.name ||
        item.ClientFormFSMS_Data?.[0]?.auditCertificationActivitiesAndFees
          ?.auditTypeStates?.[0]?.name;
      const clientAdvance_data =
        item.ClientFormIMS_Data?.[0] || item.ClientFormFSMS_Data?.[0];
      const nextStepValues = getNextStepValues(
        clientCategory,
        item.auditType,
        clientAdvance_data
      );
      console.log(nextStepValues, "nextStepValues");
      const nextAudit = {
        nextStep: nextStepValues.nextStep,
        onSite: nextStepValues.onSite,
        offSite: nextStepValues.offSite,
        message: nextStepValues.message,
        fullDetails:
          nextStepValues.nextStep &&
          nextStepValues.onSite !== null &&
          nextStepValues.offSite !== null
            ? `${nextStepValues.nextStep} / ${nextStepValues.onSite} / ${nextStepValues.offSite}`
            : "This is the final audit",
      };
      console.log(nextAudit.fullDetails);
      const getSalesPersonNameById = (id, salesList) => {
        const person = salesList.find((salesPerson) => salesPerson._id === id);
        if (person) {
          return { firstName: person.firstName, lastName: person.lastName };
        } else {
          return null; // Or handle the case when the person is not found
        }
      };
      const SaleFind = getSalesPersonNameById(
        item.ClientBasic_Data[0].salesId,
        SalesNameList
      );
      const SaleName = SaleFind.firstName + SaleFind.lastName;
      const contactPerson =
        item.ClientBasic_Data[0].email +
        " / " +
        item.ClientBasic_Data[0].contactPerson +
        " & " +
        item.ClientBasic_Data[0].phoneNumber;
      const Correctiveactions_Data__length = item.Correctiveactions_Data.length;

      return [
        item.ClientBasic_Data[0].clientId,
        item.ClientBasic_Data[0].companyName,
        item.ClientBasic_Data[0].address,
        Accredited,
        item.ClientFormIMS_Data?.[0]?.scopeOfCertification ||
          item.ClientFormFSMS_Data?.[0]?.scopeOfCertification ||
          "-",
        item.ClientBasic_Data[0].standardsSchemeType,
        item.ClientBasic_Data[0].technicalCode.mainCode,
        item.ClientBasic_Data[0].technicalCode.subCode,
        item.ClientBasic_Data[0].noOfSites,
        calculateOverallTotalManDays(item),
        PlannerName,
        item.auditType,
        item.reportStatus.name,
        nextAudit.fullDetails,
        item.ClientFormIMS_Data?.[0]?.auditCertificationActivitiesAndFees
          ?.totalContractFee ||
          item.ClientFormFSMS_Data?.[0]?.auditCertificationActivitiesAndFees
            ?.totalContractFee ||
          "-",
        item.ClientBasic_Data[0].leadGeneratedBy,
        SaleName,
        contactPerson,
        // Correctiveactions_Data__length,
      ];
    });
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Client List Data");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveAs(
      new Blob([excelBuffer], { type: "application/octet-stream" }),
      "Planner_client_list_data.xlsx"
    );
  };

  useEffect(() => {
    if (action) {
      handleClickOpen();
      setActivityType(getSpecificclientid.auditType);
      setSelectedClient(getSpecificclientid.ClientBasic_Data[0]._id);
      setScheduledAuditorsWithDates(
        getSpecificclientid.scheduledAuditorsWithDates
      );
    }
  }, [action]);
  const radioOptions = [
    { value: "approved", label: "Approved" },
    { value: "stage 1 completed", label: "STAGE 1 Completed" },
    { value: "stage 2 completed", label: "STAGE 2 Completed" },
    { value: "SA 1 Completed", label: "SA 1 Completed" },
    { value: "SA 2 Completed", label: "SA 2 Completed" },
    {
      value: "re-certification completed",
      label: "Re-certification Completed",
    },
    {
      value: "Scope Extension",
      label: "Scope Extension",
    },
  ];
  //CanCel Client
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [certificateFormDialog, setOpenCertificateFormDialog] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [selectedAudit, setSelectedAudit] = useState(null);

  const openCancelDialog = (id) => {
    setIsDialogOpen(true);
  };

  const closeCancelDialog = () => {
    setIsDialogOpen(false);
  };

  const openCertificateFormDialog = (item) => {
    setSelectedAudit(item);
    setOpenCertificateFormDialog(true);
  };

  const closeCertificateFormDialog = () => {
    setOpenCertificateFormDialog(false);
  };

  const handleCancelClient = async () => {
    const formattedDate = getFormattedIndiaTime();
    const params = {
      reportStatus: {
        name: "cancelled",
        date: formattedDate,
      },
      isCancelled: true,
      cancelledRemark: remarks,
    };
    const endpoint = await patchData(
      `${AUDITROUTES.SCHEDULEPATCH(getSpecificclientid._id)}`,
      params,
      accessToken,
      dispatch
    );
    setIsDialogOpen(false);
    AuditResult(currentPage);
  };

  // Audit Init Letter
  const [uploadResponse, setUploadResponse] = useState(null);
  const handleFileChange = async (event) => {
    setUploadResponse(null);
    const file = event.target.files[0];
    const formDataCIFAUpload = new FormData();
    formDataCIFAUpload.append("file", file);

    try {
      const CIFA_uploadRes = await FileUpload(
        `${FILEROUTES.UPLOAD}${UserId}`,
        formDataCIFAUpload,
        dispatch
      );
      setUploadResponse(CIFA_uploadRes.data);
    } catch (error) {
      console.error("File upload failed:", error);
      setUploadResponse(null);
    }
  };
  const [viewaudit, setViewAudit] = useState(false);
  const [viewauditorid, setViewAuditorId] = useState();
  const [viewauditorRole, setViewAuditorRole] = useState();
  const handleViewAudit = (auditorId, auditorDesignation) => {
    setViewAudit(true);
    setViewAuditorId(auditorId);
    setViewAuditorRole(auditorDesignation);
  };

  const [Eventopen, setEventOpen] = useState(false);
  const [Events, setEvents] = useState();
  const handleEventClickOpen = async (id) => {
    const res = await getData(
      EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(id),
      null,
      accessToken
    );
    setEvents(res.data);
    setEventOpen(true);
  };

  const handleEventClose = () => {
    setEventOpen(false);
  };

  return (
    <>
      <ToastContainer />
      <EventDialog
        open={Eventopen}
        handleClose={handleEventClose}
        events={Events}
      />
      <Dialog
        open={isDialogOpen}
        onClose={closeCancelDialog}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle>Cancel Client</DialogTitle>
        <DialogContent>
        <p className="text-danger" style={{fontSize:"14px"}}>Once the audit is canceled, it cannot be reopened. Only after confirmation can you add a remark and press the confirm button</p>
          <TextField
            autoFocus
            margin="dense"
            id="remarks"
            label="Remarks"
            type="text"
            fullWidth
            variant="standard"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClient} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <div>
        <Dialog
          open={certificateFormDialog}
          onClose={closeCertificateFormDialog}
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xl"
          fullWidth={true}
        >
          <DialogTitle>Certificate</DialogTitle>
          <DialogContent>
            <CertificateForm
              HandlAaddCertificate={closeCertificateFormDialog}
              auditData={selectedAudit}
              action={"Add"}
            ></CertificateForm>
          </DialogContent>
        </Dialog>
      </div>

      <div className="Document-border p-5">
        <div className="px-2 add-title">
          <span className="">Audit</span>
        </div>
        {viewspecificAudit ? (
          <ViewAudit
            specificClient={specificClient}
            specificAudit={specificAudit}
            AuditList={AuditList}
            BackToAudit={BackToAudit}
            setViewSpecificAudit={setViewSpecificAudit}
          />
        ) : (
          ""
        )}

        {isAuthenticated === "Planner" ||
        (isAuthenticated === "Admin" && !viewspecificAudit) ? (
          <div className="d-flex justify-content-end">
            <Button
              className="cl-green text-white"
              onClick={() => {
                handleClickOpen();
              }}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add Audit
            </Button>
          </div>
        ) : (
          ""
        )}
        {isAuthenticated === "Admin" && !viewspecificAudit && (
          <div className="d-flex flex-wrap justify-content-end mt-3">
            <Button
              variant="contained"
              className="btn-orange text-dark"
              onClick={handleExport}
            >
              Export
            </Button>

            <Button
              style={{ marginRight: "0px!important" }}
              className="btn-green text-white"
              onClick={ChangeFilter}
            >
              {filter ? "Close Filter" : "Show Filter"}
            </Button>
          </div>
        )}
        {filter && !viewspecificAudit && <AuditFilterAdmin />}

        {!viewspecificAudit && (
          <>
            <Dialog
              open={Uploadopen}
              onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                  handleClose(event, reason);
                }
              }}
              style={{ height: "100vh!important" }}
              // onClose={handleClose}
              maxWidth="xl"
              fullWidth
            >
              {viewaudit ? (
                <>
                  <DialogActions>
                    <div className="d-flex justify-content-end">
                      <Button
                        className="cl-orange text-white"
                        onClick={handleClose}
                      >
                        Close
                      </Button>
                    </div>
                  </DialogActions>
                  <AuditorCalendar
                    auditId={getSpecificclientid._id}
                    clientId={getSpecificclientid.clientId}
                    auditorRoleData={viewauditorRole}
                  />
                </>
              ) : (
                <>
                  <DialogTitle>
                    {action === "View" ? "Auditor List" : "Assign Auditor"}
                  </DialogTitle>
                  {action !== "View" ? (
                    <div className="py-1 px-3">
                      <div className="d-flex flex-wrap col-12">
                        {action === "Edit" && (
                          <>
                            <p className="w-100 px-4">
                              <span className="fw-bold pe-4">
                                Company Name :{" "}
                              </span>
                              {
                                getSpecificclientid.ClientBasic_Data[0]
                                  .companyName
                              }
                            </p>
                            {/* {getSpecificclientid && (
                              <p className="w-100 px-4">
                                <span className="fw-bold pe-4">
                                  Audit Type :{" "}
                                </span>
                                {getSpecificclientid.auditType}
                              </p>
                            )} */}
                          </>
                        )}

                        {action !== "Edit" && (
                          <div className="col-11 mx-auto mb-2">
                            <FormControl className="w-100">
                              <InputLabel id="demo-simple-select-label-13">
                                Select Status
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label-13"
                                id="demo-simple-select-13"
                                value={selectedStatus}
                                onChange={handleStatusChange}
                                label="Status"
                              >
                                <MenuItem value="">Select Status</MenuItem>
                                {radioOptions.map((option, index) => (
                                  <MenuItem key={index} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControl className="w-100 my-3">
                              <InputLabel id="demo-simple-select-label-1">
                                Client
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label-1"
                                id="demo-simple-select-1"
                                value={selectedClient}
                                disabled={selectedClient ? true : false}
                                onChange={handleClientChange}
                                label="Client"
                              >
                                {clientList &&
                                  clientList.map((Client) => (
                                    <MenuItem
                                      key={Client._id}
                                      value={Client._id}
                                    >
                                      {Client?.companyName}
                                    </MenuItem>
                                  ))}
                                {clientList && clientList.length === 0 && (
                                  <p className="text-center">No Data</p>
                                )}
                              </Select>
                            </FormControl>
                          </div>
                        )}

                        <div className="col-11 mx-auto mb-2 d-flex flex-wrap">
                          <div className="col-5">
                            {clientAdvance && (
                              <>
                                <h5>Total ManDays</h5>
                                {clientAdvance
                                  ?.auditCertificationActivitiesAndFees
                                  ?.auditTypeStates[0]?.name ===
                                  "Initial Certification" && (
                                  <>
                                    <p>
                                      stage - 1 : Onsite -{" "}
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.stage[0]?.manDay?.onSite
                                      }{" "}
                                      <span className="mx-3"></span>
                                      Offsite -
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.stage[0]?.manDay?.offSite
                                      }
                                    </p>
                                    <p>
                                      stage - 2 : Onsite -{" "}
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.stage[1]?.manDay?.onSite
                                      }{" "}
                                      <span className="mx-3"></span>
                                      Offsite -
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.stage[1]?.manDay?.offSite
                                      }
                                    </p>
                                    <p>
                                      Surveillance Audit - 1 : Onsite -{" "}
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.surveillanceAudit[0]?.manDay?.onSite
                                      }{" "}
                                      <span className="mx-3"></span>
                                      Offsite -
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.surveillanceAudit[0]?.manDay
                                          ?.offSite
                                      }
                                    </p>
                                    <p>
                                      Surveillance Audit - 2 : Onsite -
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.surveillanceAudit[0]?.manDay?.onSite
                                      }{" "}
                                      <span className="mx-3"></span>
                                      Offsite -
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.surveillanceAudit[0]?.manDay
                                          ?.offSite
                                      }
                                    </p>
                                  </>
                                )}
                                {clientAdvance
                                  ?.auditCertificationActivitiesAndFees
                                  ?.auditTypeStates[0]?.name ===
                                  "Transfer Certification in SA1" && (
                                  <>
                                    <p>
                                      Surveillance Audit - 1 : OnSite -{" "}
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.surveillanceAudit[0]?.manDay?.onSite
                                      }
                                      <span className="mx-3"></span>
                                      OffSite -
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.surveillanceAudit[0]?.manDay
                                          ?.offSite
                                      }
                                    </p>
                                    <p>
                                      Surveillance Audit - 2 : OnSite -{" "}
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.surveillanceAudit[1]?.manDay?.onSite
                                      }
                                      <span className="mx-3"></span>
                                      OffSite -
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.surveillanceAudit[1]?.manDay
                                          ?.offSite
                                      }
                                    </p>
                                  </>
                                )}
                                {clientAdvance
                                  ?.auditCertificationActivitiesAndFees
                                  ?.auditTypeStates[0]?.name ===
                                  "Transfer Certification in SA2" && (
                                  <>
                                    <p>
                                      Surveillance Audit - 2 : OnSite -{" "}
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.surveillanceAudit[1]?.manDay?.onSite
                                      }
                                      <span className="mx-3"></span>
                                      OffSite -
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.surveillanceAudit[1]?.manDay
                                          ?.offSite
                                      }
                                    </p>
                                  </>
                                )}
                                {clientAdvance
                                  ?.auditCertificationActivitiesAndFees
                                  ?.auditTypeStates[0]?.name ===
                                  "Transfer Certification in Re-Certification" && (
                                  <>
                                    <p>
                                      Re-Certification : OnSite -{" "}
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.re_Certification[0]?.manDay?.onSite
                                      }
                                      <span className="mx-3"></span>
                                      Offsite -
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.re_Certification[0]?.manDay?.offSite
                                      }
                                    </p>
                                    <p>
                                      Surveillance Audit - 1 : OnSite -{" "}
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.surveillanceAudit[1]?.manDay?.onSite
                                      }
                                      <span className="mx-3"></span>
                                      Offsite -
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.surveillanceAudit[1]?.manDay
                                          ?.offSite
                                      }
                                    </p>
                                    <p>
                                      Surveillance Audit - 2 : OnSite -{" "}
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.surveillanceAudit[2]?.manDay?.onSite
                                      }
                                      <span className="mx-3"></span>
                                      Offsite -
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.surveillanceAudit[2]?.manDay
                                          ?.offSite
                                      }
                                    </p>
                                  </>
                                )}
                                {clientAdvance
                                  ?.auditCertificationActivitiesAndFees
                                  ?.auditTypeStates[0]?.name ===
                                  "Re-Certification" && (
                                  <>
                                    <p>
                                      Re-Certification : OnSite -{" "}
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.re_Certification[0]?.manDay?.onSite
                                      }
                                      <span className="mx-3"></span>
                                      OffSite -
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.re_Certification[0]?.manDay?.offSite
                                      }
                                    </p>
                                    <p>
                                      Surveillance Audit - 1 : OnSite -{" "}
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.surveillanceAudit[1]?.manDay?.onSite
                                      }
                                      <span className="mx-3"></span>
                                      OffSite -
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.surveillanceAudit[1]?.manDay
                                          ?.offSite
                                      }
                                    </p>
                                    <p>
                                      Surveillance Audit - 2 : OnSite -{" "}
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.surveillanceAudit[2]?.manDay?.onSite
                                      }
                                      <span className="mx-3"></span>
                                      OffSite -{" "}
                                      {
                                        clientAdvance
                                          .auditCertificationActivitiesAndFees
                                          ?.surveillanceAudit[2]?.manDay
                                          ?.offSite
                                      }
                                    </p>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                          <div className="col-5">
                            {clientBasic && (
                              <>
                                <h5>Completed Audit</h5>
                                {clientBasic.auditStatesAndStatuses.map(
                                  (item, index) => (
                                    <div key={index}>
                                      <p>Status: {item.status}</p>
                                      <p>
                                        Date:{" "}
                                        {moment(item.date)
                                          .tz("Asia/Kolkata")
                                          .format("YYYY-MM-DD / HH:mm:ss")}
                                      </p>
                                      <hr />
                                    </div>
                                  )
                                )}
                                {clientBasic.auditStatesAndStatuses.length ===
                                  0 && <p>NO Audit Completed</p>}
                              </>
                            )}
                          </div>
                        </div>

                        <div className="col-5 mx-auto d-flex">
                          <FormControlLabel
                            value="ONE_DAY"
                            control={<Radio />}
                            label="One Day"
                            checked={selectedManDays === "ONE_DAY"}
                            disabled={selectedManDays ? true : false}
                            onChange={handleChangeManDays}
                          />
                          <FormControlLabel
                            value="FIRST_HALF"
                            control={<Radio />}
                            label="First Half"
                            checked={selectedManDays === "FIRST_HALF"}
                            disabled={selectedManDays ? true : false}
                            onChange={handleChangeManDays}
                          />
                          <FormControlLabel
                            value="SECOND_HALF"
                            control={<Radio />}
                            label="Second Half"
                            checked={selectedManDays === "SECOND_HALF"}
                            disabled={selectedManDays ? true : false}
                            onChange={handleChangeManDays}
                          />
                        </div>
                        <div className="col-5 mx-auto">
                          <TextField
                            className="w-100"
                            type="date"
                            name="Date"
                            disabled={dateval ? true : false}
                            value={dateval}
                            min={getTodayDate()}
                            size="medium"
                            onChange={(e) => setDate(e.target.value)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              min: getTodayDate(),
                            }}
                          />
                        </div>
                        <div className="col-5 mx-auto mt-3">
                          <FormControl className="w-100">
                            <InputLabel id="demo-simple-select-label">
                              Available Auditor
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={selectedAuditor}
                              onChange={handleAuditorChange}
                              label="Auditor"
                            >
                              <MenuItem value="">Select Auditor</MenuItem>
                              {auditors &&
                                auditors.map((auditor) => (
                                  <MenuItem
                                    key={auditor._id}
                                    value={auditor._id}
                                  >
                                    {auditor.firstName} {auditor.lastName}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </div>
                        {action !== "Edit" && (
                          <div className="col-5 mx-auto mt-3">
                            <FormControl className="w-100">
                              <InputLabel id="demo-simple-select-label-2">
                                AuditType
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label-2"
                                id="demo-simple-select-2"
                                value={activityType}
                                disabled={activityType ? true : false}
                                onChange={handleActivityType}
                                label="Client"
                              >
                                <MenuItem value="">
                                  Select activityType
                                </MenuItem>
                                {selectedStatus === "Scope Extension" ? (
                                  <MenuItem
                                    key={"Scope Extension"}
                                    value={"Scope Extension"}
                                  >
                                    Scope Extension
                                  </MenuItem>
                                ) : (
                                  [
                                    "STAGE_1",
                                    "STAGE_2",
                                    "SA_1",
                                    "SA_2",
                                    "RECERTIFICATION",
                                    "TRANSFER_AUDIT_IN_RA",
                                  ].map((item) => (
                                    <MenuItem key={item} value={item}>
                                      {item}
                                    </MenuItem>
                                  ))
                                )}
                              </Select>
                            </FormControl>
                          </div>
                        )}
                        <div className="col-5 mx-auto mt-3">
                          <FormControl className="w-100">
                            <InputLabel id="demo-simple-select-label-3">
                              Auditor Designation
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label-3"
                              id="demo-simple-select-3"
                              value={auditorDesignation}
                              onChange={handleauditorDesignation}
                              label="Client"
                            >
                              <MenuItem value="">
                                Select Auditor Designation
                              </MenuItem>
                              {[
                                "Lead Auditor",
                                "Technical Expert",
                                "Surprise Auditor",
                                "Auditor",
                                "Auditor Under Trainee",
                                "Observer",
                              ].map((item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-5 mx-auto mt-3">
                          <FormControl className="w-100">
                            <InputLabel id="demo-simple-select-label-4">
                              Audit Standards
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label-4"
                              id="demo-simple-select-4"
                              value={auditStandards}
                              onChange={handleauditStandards}
                              label="Client"
                            >
                              <MenuItem value="">
                                Select Audit Standards
                              </MenuItem>
                              {["QMS", "EMS", "OHSMS", "FSMS"].map((item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-11 mx-auto mt-3 d-flex justify-content-end align-items-end">
                          <Button
                            variant="contained"
                            color="primary"
                            className="mx-3 cl-orange text-white"
                            onClick={CancelAuditor}
                          >
                            Cancel
                          </Button>
                          <Button
                            disabled={
                              activityType &&
                              selectedAuditor &&
                              selectedClient &&
                              dateval
                                ? false
                                : true
                            }
                            variant="contained"
                            color="primary"
                            onClick={handleAddAuditor}
                          >
                            Add Auditor
                          </Button>
                        </div>
                      </div>

                      <div>
                        {action !== "Edit" && (
                          <h6 className="py-3">
                            Scheduled Auditors:{" "}
                            {scheduledAuditorsWithDates.length}
                          </h6>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="px-5 py-1">
                      {getSpecificclientid.scheduledAuditorsWithDates.map(
                        (auditor) => {
                          // Find the corresponding auditor object
                          const matchingAuditor = AuditorNameList.find(
                            (item) => item._id === auditor.auditorId
                          );

                          return (
                            <div key={auditor._id} className="py-3">
                              <p>
                                <span className="fw-bold pe-4">Id:</span>{" "}
                                {auditor.auditorId}
                              </p>
                              <p>
                                <span className="fw-bold pe-4">
                                  First Name:
                                </span>{" "}
                                {matchingAuditor
                                  ? matchingAuditor.firstName
                                  : "N/A"}
                              </p>
                              <p>
                                <span className="fw-bold pe-4">Last Name:</span>{" "}
                                {matchingAuditor
                                  ? matchingAuditor.lastName
                                  : "N/A"}
                              </p>
                              <p>
                                <span className="fw-bold pe-4">
                                  Designation:
                                </span>{" "}
                                {auditor.auditorDesignation}
                              </p>
                              <p>
                                <span className="fw-bold pe-4">
                                  Scheduled Date:
                                </span>{" "}
                                {new Date(
                                  auditor.scheduledDate
                                ).toLocaleDateString()}
                              </p>
                              {isAuthenticated === "Admin" && (
                                <Button
                                  onClick={() =>
                                    handleViewAudit(
                                      auditor.auditorId,
                                      auditor.auditorDesignation
                                    )
                                  }
                                >
                                  View Audit
                                </Button>
                              )}
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                  {action === "Edit" && (
                    <>
                      <div className="px-5 py-1">
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>First Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell>Designation</TableCell>
                                <TableCell>Scheduled Date</TableCell>
                                <TableCell>Action</TableCell>{" "}
                                {/* Add this column for delete button */}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {scheduledAuditorsWithDates.map((auditor) => {
                                const matchingAuditor = AuditorNameList.find(
                                  (item) => item._id === auditor.auditorId
                                );
                                return (
                                  <TableRow key={auditor._id}>
                                    <TableCell>{auditor.auditorId}</TableCell>
                                    <TableCell>
                                      {matchingAuditor
                                        ? matchingAuditor.firstName
                                        : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                      {matchingAuditor
                                        ? matchingAuditor.lastName
                                        : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                      {auditor.auditorDesignation}
                                    </TableCell>
                                    <TableCell>
                                      {new Date(
                                        auditor.scheduledDate
                                      ).toLocaleDateString()}
                                    </TableCell>
                                    <TableCell>
                                      <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() =>
                                          AuditorRemove(
                                            auditor.auditorId,
                                            auditor.scheduledDate
                                          )
                                        }
                                      >
                                        Delete
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </>
                  )}
                  {(action !== "View" ||
                    getSpecificclientid?.reportStatus?.name !==
                      "Special Audit Needed" ||
                    getSpecificclientid?.reportStatus?.name !== "") && (
                    <div>
                      <div>
                        <label
                          htmlFor={`fileInput_1`}
                          className="text-success p-4 fw-bold"
                          style={{ cursor: "pointer" }}
                        >
                          <Iconify
                            icon={"material-symbols:upload-sharp"}
                            sx={{ mr: 2 }}
                          />
                          Upload Audit Intimate Letter
                        </label>
                        <input
                          id={`fileInput_1`}
                          style={{ display: "none" }}
                          type="file"
                          onChange={handleFileChange}
                        />
                      </div>
                      {uploadResponse && (
                        <div>
                          <a
                            className="ms-3"
                            rel="noreferrer"
                            href={`${SERVER_URL}/${uploadResponse?.uri}`}
                            target="_blank"
                          >
                            {uploadResponse?.uri}
                          </a>
                        </div>
                      )}
                    </div>
                  )}
                  <DialogActions>
                    <div className="d-flex my-3">
                      {/* {scheduledAuditorsWithDates.length === 0 ||
                    action === "View" ||
                    (action === "Edit" && ( */}
                      <Button
                        className="cl-orange text-white"
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                      {/* ))} */}
                      {action !== "View" && (
                        <Button
                          variant="contained"
                          className="mx-3"
                          disabled={
                            scheduledAuditorsWithDates.length !== 0 &&
                            uploadResponse !== null
                              ? false
                              : true
                          }
                          onClick={scheduleAuditor}
                        >
                          Submit
                        </Button>
                      )}
                    </div>
                  </DialogActions>
                </>
              )}
            </Dialog>
            <Card className="mt-4">
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {isAuthenticated === "Admin" && (
                        <TableCell
                          className="cl-green text-dark"
                          style={{ padding: "5px" }}
                        >
                          <Checkbox
                            checked={selectAllChecked}
                            onChange={handleSelectAllCheckboxChange}
                          />
                        </TableCell>
                      )}

                      <TableCell className="cl-green text-dark">
                        Client Id
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Company Name
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Phone Number
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Technical Main Code
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Technical Sub Code
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Location
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Audit Type
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        SchemeType
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Status
                      </TableCell>
                      {isAuthenticated === "Admin" && (
                        <TableCell className="cl-green text-dark">
                          Corrective Action Status
                          {/* {AuditList ? (
                            AuditList.Correctiveactions_Data ? (
                              <>
                                {console.log(AuditList.Correctiveactions_Data)}
                                {AuditList.Correctiveactions_Data?.[0].status ||
                                  "Status not available"}
                              </>
                            ) : (
                              "Pending"
                            )
                          ) : (
                            "No Audit list"
                          )} */}
                        </TableCell>
                      )}
                      {isAuthenticated === "CSO" ||
                      isAuthenticated === "Admin" ? (
                        <>
                          <TableCell className="cl-green text-dark">
                            Reviewer Name
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Assign
                          </TableCell>
                        </>
                      ) : (
                        ""
                      )}
                      {isAuthenticated === "Reviewer" ||
                      isAuthenticated === "Admin" ? (
                        <TableCell
                          className="cl-green text-dark"
                          align="center"
                        >
                          ReView Audit
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {isAuthenticated == "Admin" && (
                        <TableCell className="cl-green text-dark">
                          Cancel Remark
                        </TableCell>
                      )}
                      {isAuthenticated == "Admin" ||
                      isAuthenticated == "CSO" ? (
                        <TableCell
                          className="cl-green text-dark"
                          align="center"
                        >
                          Certificate Action
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {isAuthenticated === "Planner" ||
                      isAuthenticated === "Admin" ? (
                        <TableCell
                          className="cl-green text-dark"
                          align="center"
                        >
                          Action
                        </TableCell>
                      ) : (
                        ""
                      )}
                      <TableCell className="cl-green text-dark" align="center">
                        Events
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {AuditList && AuditList.length !== 0 ? (
                      AuditList.map((row) => {
                        const {
                          _id,
                          ClientBasic_Data,
                          reviewerId,
                          reportStatus,
                          auditType,
                          clientId,
                          cancelledRemark,
                          Correctiveactions_Data,
                          isSpecialAudit,
                        } = row;
                        return (
                          <TableRow key={_id}>
                            {isAuthenticated === "Admin" && (
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isChecked[_id] || false}
                                  onChange={(event) =>
                                    handleCheckboxChange(event, _id)
                                  }
                                />
                              </TableCell>
                            )}

                            <TableCell>
                              {ClientBasic_Data[0] &&
                                ClientBasic_Data[0]?.clientId}
                            </TableCell>
                            <TableCell>
                              {ClientBasic_Data[0] &&
                                ClientBasic_Data[0]?.companyName}
                            </TableCell>
                            <TableCell>
                              {ClientBasic_Data[0] &&
                                ClientBasic_Data[0]?.phoneNumber}
                            </TableCell>
                            <TableCell>
                              {ClientBasic_Data[0] &&
                                ClientBasic_Data[0]?.technicalCode?.mainCode}
                            </TableCell>
                            <TableCell>
                              {ClientBasic_Data[0] &&
                                ClientBasic_Data[0]?.technicalCode?.subCode}
                            </TableCell>
                            <TableCell>
                              {ClientBasic_Data[0] && ClientBasic_Data[0].state}{" "}
                              /
                              {ClientBasic_Data[0] &&
                                ClientBasic_Data[0].country}{" "}
                            </TableCell>
                            <TableCell>{auditType}</TableCell>
                            <TableCell>
                              {ClientBasic_Data[0] &&
                                ClientBasic_Data[0].standardsSchemeType}
                            </TableCell>
                            <TableCell>
                              {reportStatus
                                ? reportStatus?.name || reportStatus
                                : "-"}
                            </TableCell>
                            {isAuthenticated === "Admin" &&
                              Correctiveactions_Data && (
                                <TableCell>
                                  {Correctiveactions_Data.length}
                                </TableCell>
                              )}

                            {isAuthenticated === "CSO" ||
                            isAuthenticated === "Admin" ? (
                              <>
                                <TableCell align="center">
                                  {reviewerId ? (
                                    <>
                                      {
                                        findUserById(String(reviewerId))
                                          .firstName
                                      }{" "}
                                      {
                                        findUserById(String(reviewerId))
                                          .lastName
                                      }
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  {reportStatus?.name ===
                                  "Audit report completed" ? (
                                    <FormControl fullWidth size="small">
                                      <InputLabel id={_id}>Assign</InputLabel>
                                      <Select
                                        labelId={_id}
                                        id={`demo-simple-select-${_id}`}
                                        value={selectedValues[_id] || ""}
                                        label="Reviewer List"
                                        onChange={(e) =>
                                          handleChange(
                                            e,
                                            _id,
                                            auditType,
                                            ClientBasic_Data
                                          )
                                        }
                                        MenuProps={{
                                          PaperProps: {
                                            style: { maxHeight: "200px" },
                                          },
                                        }}
                                      >
                                        {ReviewerList &&
                                          ReviewerList.map((item) => (
                                            <MenuItem
                                              key={item._id}
                                              value={item._id}
                                            >
                                              {item.firstName} {item.lastName}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                    </FormControl>
                                  ) : (
                                    "-"
                                  )}
                                </TableCell>
                              </>
                            ) : (
                              ""
                            )}
                            {isAuthenticated === "Reviewer" ||
                            isAuthenticated === "Admin" ? (
                              <TableCell align="center">
                                {reportStatus.name ===
                                  "Technical Report Review Pending" ||
                                reportStatus.name ===
                                  "Need Revision Completed" ||
                                isAuthenticated === "Admin" ||
                                reportStatus.name ===
                                  "Audit report completed" ||
                                reportStatus.name ===
                                  "STAGE_1 Technical Report Review Completed" ||
                                reportStatus.name ===
                                  "STAGE_2 Technical Report Review Completed" ||
                                reportStatus.name ===
                                  "SA_1 Technical Report Review Completed" ||
                                reportStatus.name ===
                                  "SA_2 Technical Report Review Completed" ||
                                reportStatus.name ===
                                  "RECERTIFICATION Technical Report Review Completed" ||
                                reportStatus.name ===
                                  "TRANSFER_AUDIT_IN_RA Technical Report Review Completed" ||
                                reportStatus.name ===
                                  "Technical Report Review Completed" ||
                                (isSpecialAudit &&
                                  reportStatus.name ===
                                    "Audit report completed") ? (
                                  <Iconify
                                    style={{ cursor: "pointer" }}
                                    className="cursor-pointer"
                                    icon={"raphael:view"}
                                    onClick={() => {
                                      setSpecificAudit(_id);
                                      setViewSpecificAudit(true);
                                    }}
                                  />
                                ) : (
                                  "-"
                                )}
                              </TableCell>
                            ) : (
                              ""
                            )}
                            {isAuthenticated === "Admin" && (
                              <TableCell align="center">
                                {cancelledRemark ? cancelledRemark : "-"}
                              </TableCell>
                            )}
                            {isAuthenticated === "Admin" ||
                            (isAuthenticated === "CSO" &&
                              reportStatus.name ===
                                "Certificate Decision Completed") ? (
                              <TableCell align="center">
                                <Button
                                  variant="contained"
                                  className="btn-orange text-dark me-0"
                                  startIcon={<Iconify icon="eva:plus-fill" />}
                                  onClick={() => openCertificateFormDialog(row)}
                                >
                                  Add Certificate
                                </Button>
                              </TableCell>
                            ) : isAuthenticated === "CSO" &&
                              reportStatus.name !==
                                "Certificate Decision Completed" ? (
                              <TableCell align="center">-</TableCell>
                            ) : null}

                            {isAuthenticated === "Planner" ||
                            isAuthenticated === "Admin" ? (
                              <TableCell align="center">
                                <IconButton
                                  size="large"
                                  color="inherit"
                                  onClick={(event) =>
                                    handleOpenMenu(event, _id)
                                  }
                                >
                                  <Iconify icon={"eva:more-vertical-fill"} />
                                </IconButton>
                              </TableCell>
                            ) : (
                              ""
                            )}
                            <TableCell>
                              <Button
                                onClick={() => {
                                  handleEventClickOpen(clientId);
                                }}
                              >
                                <Iconify icon={"raphael:view"} sx={{ mr: 2 }} />
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <h6 className="text-center my-3 w-100">No Data</h6>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {AuditList && (
                <div className="d-flex flex-wrap justify-content-end m-3">
                  <FormControl variant="outlined" size="small">
                    <InputLabel id="limit-label">Limit</InputLabel>
                    <Select
                      labelId="limit-label"
                      id="limit-select"
                      value={limit}
                      onChange={handleLimitChange}
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                    </Select>
                  </FormControl>
                  <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <Iconify icon={"grommet-icons:previous"} />
                  </Button>
                  <Button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <Iconify icon={"grommet-icons:next"} />
                  </Button>
                </div>
              )}
            </Card>
            <Popover
              open={Boolean(open)}
              anchorEl={open}
              onClose={handleCloseMenu}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              PaperProps={{
                sx: {
                  p: 1,
                  width: 140,
                  "& .MuiMenuItem-root": {
                    px: 1,
                    typography: "body2",
                    borderRadius: 0.75,
                  },
                },
              }}
            >
              {(getSpecificclientid?.reportStatus?.name ===
                "Auditor Assigned & Audit Intimation Letter Sent" ||
                getSpecificclientid?.reportStatus?.name ===
                  "Special Audit Needed" ||
                isAuthenticated === "Admin") && (
                <MenuItem
                  onClick={() => {
                    setOpen(null);
                    setAction("Edit");
                  }}
                >
                  <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
                  Edit
                </MenuItem>
              )}

              <MenuItem
                onClick={() => {
                  setAction("View");
                  setOpen(null);
                }}
              >
                <Iconify icon={"raphael:view"} sx={{ mr: 2 }} />
                View
              </MenuItem>
              {isAuthenticated === "Admin" && (
                <MenuItem
                  sx={{ color: "error.main" }}
                  onClick={() => openCancelDialog(getSpecificclientid)}
                >
                  <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
                  Cancel
                </MenuItem>
              )}
            </Popover>
          </>
        )}
      </div>
    </>
  );
}

export default Audit;
