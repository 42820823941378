import React, { useEffect, useState } from "react";
import "../ClientBasic_Advance/ClientBasic_Advance.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import CLIENTROUTES from "src/API_Routes/Client/Client_Routers";
import { patchData, postData, getData } from "src/webService/webService";
import EVENT_TRACKING_ROUTES from "../../API_Routes/EventTracking/EventTracking_Routers";
import EMAIL_ROUTES from "../../API_Routes/Email/Email_Routers";
import CommonEmail from "../../Config/templates/html/CommonEmailTem";
import { getFormattedIndiaTime } from "../../utils/timeutils";
import SERVER_URL from "../../utils/serverURL";
import { useDispatch } from "react-redux";
const renderFileLinks = (data, keyPrefix) => {
  if (data && data.CIFA_FileObjects && data.CIFA_FileObjects.length > 0) {
    return data.CIFA_FileObjects.map((fileObject, index) => (
      <p key={`${keyPrefix}_${index}`}>
        <a href={`${SERVER_URL}/${fileObject.uri}`} target="_blank">
          {`Cifa(${index + 1}).pdf`}
        </a>
      </p>
    ));
  }
  return "No Data";
};
export default function ClientBasicAdvance({
  handleViewClient,
  specificClientData,
}) {
  const FileLinks = () => {
    if (imsData) {
      return renderFileLinks(imsData, "ims");
    } else if (fsmsData) {
      return renderFileLinks(fsmsData, "fsms");
    } else {
      return "No Data";
    }
  };
  const dispatch = useDispatch();
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const specificClientDataSalesId = specificClientData.salesId;
  console.log("specificClientData", specificClientData);
  const scheme_session = sessionStorage.getItem("scheme");
  const accessToken = sessionStorage.getItem("Token");
  const imsData =
    scheme_session === "CB"
      ? specificClientData && specificClientData.ClientFormIMS_Data[0]
      : null;

  const fsmsData =
    scheme_session === "CB"
      ? specificClientData && specificClientData.ClientFormFSMS_Data[0]
      : null;
  const HRAData =
    scheme_session === "HRA"
      ? specificClientData && specificClientData.ClientFormHRA_Data[0]
      : null;
  // const renderTextField = (label, value) => (
  //   <div className="col-4 px-2 py-3">
  //     <TextField className="w-100" label={label} value={value} size="small" />
  //   </div>
  // );
  const renderTextField = (label, value, multiline = false) => (
    <div className="col-4 px-2 py-3">
      <TextField
        className="w-100"
        label={label}
        value={value}
        size="small"
        multiline={multiline}
        rows={multiline ? 4 : 1}
      />
    </div>
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //Get SalesList
  const [SalesList, setSalesList] = useState();
  const GETSales = async () => {
    try {
      const res = await getData(
        `masters/user/basic/nameandid/Sales`,
        null,
        accessToken
      );
      if (res.status === 200) {
        setSalesList(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    GETSales();
  }, []);

  const updateReviewerStatus = async (val) => {
    const formattedDate = getFormattedIndiaTime();
    let newStatusTracking = null;
    if (val === "approved") {
      newStatusTracking = {
        year: "1",
        status: "Reviewer Approved",
        date: formattedDate,
      };
    }
    if (val === "need revision") {
      newStatusTracking = {
        year: "1",
        status: "Reviewer Need Revision",
        date: formattedDate,
      };
    }
    if (val === "rejected") {
      newStatusTracking = {
        year: "1",
        status: "Reviewer Rejected",
        date: formattedDate,
      };
    }

    const updatedClientStatusTracker = newStatusTracking
      ? [...specificClientData.clientStatusTracker, newStatusTracking]
      : specificClientData.clientStatusTracker;

    const Params = {
      status: {
        type: val,
        updatedDate: formattedDate,
      },
      ...((val === "approved" ||
        val === "need revision" ||
        val === "rejected") && {
        clientStatusTracker: updatedClientStatusTracker,
      }),
    };
    const endpoint = `${CLIENTROUTES.GETCLIENTADVANCE}${specificClientData._id}/basic`;
    patchData(endpoint, Params, accessToken, dispatch);
    await postData(
      EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(specificClientData._id),
      {
        description: `Reviewer Change The ${val} Status`,
        performedBy: `${sessionStorage.getItem(
          "firstName"
        )} ${sessionStorage.getItem("lastName")}`,
      },
      accessToken,
      dispatch
    );
    if (val === "need revision") {
      // const user = SalesList.find(
      //   (user) => user._id === specificClientDataSalesId
      // );
      // const specificClientDataSalesMail = user.email;

      // const HTML_DATA = {
      //   ClientName: specificClientData.companyName,
      //   ContactPerson: specificClientData.contactPerson,
      //   ClientID: specificClientData.clientId,
      //   AuditType: "INITIAL_ACCREDITATION_AND_ADMIN_COST",
      //   Message: "The above document needs revision and more info required",
      // };
      // await postData(
      //   EMAIL_ROUTES.SEND_EMAIL_TEXT,
      //   {
      //     to: specificClientDataSalesMail,
      //     subject: "Need Revision",
      //     html: CommonEmail(HTML_DATA),
      //   },
      //   accessToken
      // );
      handleDialogOpen();
    } else {
      handleViewClient();
    }
  };
  const [SalesNameEmail, setSalesNameEmail] = useState();
  useEffect(() => {
    if (SalesList) {
      const user = SalesList.find(
        (user) => user._id === specificClientDataSalesId
      );
      setSalesNameEmail(user);
    }
  }, [SalesList]);
  console.log(SalesNameEmail, "SalesNameEmail");

  //Need Revision
  const [openDialog, setOpenDialog] = useState(false);
  const [revisionMessage, setRevisionMessage] = useState("");

  const handleDialogOpen = () => setOpenDialog(true);
  const handleDialogClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenDialog(false);
    }
  };
  const handleRevisionMessageChange = (event) =>
    setRevisionMessage(event.target.value);
  const handleRevisionSubmit = async () => {
    handleDialogClose();

    const user = SalesList.find(
      (user) => user._id === specificClientDataSalesId
    );
    const specificClientDataSalesMail = user.email;

    const HTML_DATA = {
      ClientName: specificClientData.companyName,
      ContactPerson: specificClientData.contactPerson,
      ClientID: specificClientData.clientId,
      AuditType: "INITIAL_ACCREDITATION_AND_ADMIN_COST",
      Message: revisionMessage,
    };

    await postData(
      EMAIL_ROUTES.SEND_EMAIL_TEXT,
      {
        to: specificClientDataSalesMail,
        subject: "Need Revision",
        html: CommonEmail(HTML_DATA),
      },
      accessToken,
      dispatch
    );

    handleViewClient();
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>Need Revision</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the revision message below:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="revisionMessage"
            label="Revision Message"
            type="text"
            fullWidth
            value={revisionMessage}
            onChange={handleRevisionMessageChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRevisionSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <div className="clientbasicadvance-border p-5">
        <div className="px-2 add-title">
          <span className="">View Client</span>
        </div>
        <h6 className="my-3 fw-bold">Client Details</h6>
        <div className="d-flex flex-wrap">
          <div className="col-4 px-2 py-3">
            <TextField
              className="w-100"
              name="companyName"
              label="company Name"
              value={specificClientData?.companyName}
              size="small"
            />
          </div>
          <div className="col-4 px-2 py-3">
            <TextField
              className="w-100"
              name="email"
              label="Email"
              value={specificClientData?.email}
              size="small"
            />
          </div>
          <div className="col-4 px-2 py-3">
            <TextField
              className="w-100"
              name="mobileNumber"
              label="Mobile Number"
              value={specificClientData?.phoneNumber}
              size="small"
            />
          </div>
          <div className="col-4 px-2 py-3">
            <TextField
              className="w-100"
              name="clientId"
              label="ClientId"
              value={specificClientData?.clientId}
              size="small"
            />
          </div>
          {scheme_session !== "HRA" && (
            <>
              <div className="col-4 px-2 py-3">
                <TextField
                  className="w-100"
                  name="technicalCode"
                  label="Technical Main Code"
                  value={specificClientData?.technicalCode?.mainCode}
                  size="small"
                />
              </div>
              <div className="col-4 px-2 py-3">
                <TextField
                  className="w-100"
                  name="technicalCode"
                  label="Technical Sub Code"
                  value={specificClientData?.technicalCode?.subCode}
                  size="small"
                />
              </div>
            </>
          )}
          <div className="col-4 px-2 py-3">
            <TextField
              className="w-100"
              name="scheme"
              label="Scheme"
              value={specificClientData?.standardsSchemeType}
              size="small"
            />
          </div>
          {scheme_session !== "HRA" && (
            <div className="col-12 px-2 py-3">
              <TextField
                className="w-100"
                name="address"
                label="Address"
                value={specificClientData?.address}
                size="small"
              />
            </div>
          )}

          {scheme_session === "HRA" && (
            <>
              <div className="col-4 px-2 py-3">
                <TextField
                  className="w-100"
                  name="doorNo"
                  label="Door No"
                  value={specificClientData?.doorNo}
                  size="small"
                />
              </div>
              <div className="col-4 px-2 py-3">
                <TextField
                  className="w-100"
                  name="streetName"
                  label="streetName"
                  value={specificClientData?.streetName}
                  size="small"
                />
              </div>
              <div className="col-4 px-2 py-3">
                <TextField
                  className="w-100"
                  name="city"
                  label="city"
                  value={specificClientData?.city}
                  size="small"
                />
              </div>
              <div className="col-4 px-2 py-3">
                <TextField
                  className="w-100"
                  name="district"
                  label="District"
                  value={specificClientData?.district}
                  size="small"
                />
              </div>
              <div className="col-4 px-2 py-3">
                <TextField
                  className="w-100"
                  name="pincode"
                  label="pincode"
                  value={specificClientData?.pincode}
                  size="small"
                />
              </div>
            </>
          )}
          <div className="col-4 px-2 py-3">
            <TextField
              className="w-100"
              name="state"
              label="State"
              value={specificClientData?.state}
              size="small"
            />
          </div>
          <div className="col-4 px-2 py-3">
            <TextField
              className="w-100"
              name="country"
              label="Country"
              value={specificClientData?.country}
              size="small"
            />
          </div>
          <div className="col-4 px-2 py-3">
            <TextField
              className="w-100"
              name="NoOfSites"
              label="No Of Sites"
              value={specificClientData?.noOfSites}
              size="small"
            />
          </div>
          <div className="col-4 px-2 py-3">
            <TextField
              className="w-100"
              name="contactPersonName"
              label="Contact Person Name"
              value={specificClientData?.contactPerson}
              size="small"
            />
          </div>
        </div>

        {scheme_session === "CB" ? (
          <>
            <div className="d-flex flex-wrap justify-content-start">
              {renderTextField(
                "proposalDate",
                imsData
                  ? imsData.proposalDate
                  : fsmsData
                  ? fsmsData.proposalDate
                  : "No Data"
              )}
              {renderTextField(
                "Title",
                imsData ? imsData.title : fsmsData ? fsmsData.title : "No Data"
              )}
              {renderTextField(
                "City",
                imsData ? imsData.city : fsmsData ? fsmsData.city : "No Data"
              )}

              {renderTextField(
                "Post Code",
                imsData
                  ? imsData.postCode
                  : fsmsData
                  ? fsmsData.postCode
                  : "No Data"
              )}
              {imsData
                ? renderTextField(
                    "Billing Contact Name",
                    imsData.billingContact
                      ? imsData.billingContact.name
                      : fsmsData.billingContact
                      ? fsmsData.billingContact.name
                      : "No Data"
                  )
                : ""}

              {imsData
                ? renderTextField(
                    "Billing Contact Designation",
                    imsData.billingContact
                      ? imsData.billingContact.designation
                      : fsmsData.billingContact
                      ? fsmsData.billingContact.designation
                      : "No Data"
                  )
                : ""}
              {renderTextField(
                "Exp Date Of Audit Planned",
                imsData
                  ? imsData.expectedDateOfAuditPlanned
                  : fsmsData
                  ? fsmsData.expectedDateOfAuditPlanned
                  : "No Data"
              )}
              {renderTextField(
                "PAN",
                imsData ? imsData.PAN : fsmsData ? fsmsData.PAN : "No Data"
              )}
              {renderTextField(
                "GST Number",
                imsData
                  ? imsData.GSTNumber
                  : fsmsData
                  ? fsmsData.GSTNumber
                  : "No Data"
              )}
              {renderTextField(
                "Local Resource",
                imsData
                  ? imsData.localResource
                  : fsmsData
                  ? fsmsData.localResource
                  : "No Data"
              )}
            </div>
            <h6 className="my-3 fw-bold">Proposal Information</h6>
            <div className="d-flex flex-wrap justify-content-start">
              {renderTextField(
                "standards",
                imsData
                  ? imsData.standards[0]
                  : fsmsData
                  ? fsmsData.standards[0]
                  : "No Data"
              )}
              {renderTextField(
                "Certification Body",
                imsData
                  ? imsData.certificationBody
                  : fsmsData
                  ? fsmsData.certificationBody
                  : "No Data"
              )}
              {renderTextField(
                "CAUSSA Contracting Office",
                imsData
                  ? imsData.CAUSSA_ContractingOffice
                  : fsmsData
                  ? fsmsData.CAUSSA_ContractingOffice
                  : "No Data"
              )}
              {renderTextField(
                "Accreditations",
                imsData
                  ? imsData.accreditations[0]
                  : fsmsData
                  ? fsmsData.accreditations[0]
                  : "No Data"
              )}
              {/* {renderTextField(
                "Scope Of Certification",
                imsData
                  ? imsData.scopeOfCertification
                  : fsmsData
                  ? fsmsData.scopeOfCertification
                  : "No Data"
              )} */}
              {renderTextField(
                "Scope Of Certification",
                imsData
                  ? imsData.scopeOfCertification
                  : fsmsData
                  ? fsmsData.scopeOfCertification
                  : "No Data",
                true
              )}
              {fsmsData
                ? renderTextField("No of HACCP Study", fsmsData.NoOfHACCP_study)
                : ""}

              {renderTextField(
                "Total No Of Employees",
                imsData
                  ? imsData.totalNoOfEmployees
                  : fsmsData
                  ? fsmsData.totalNoOfEmployees
                  : "No Data"
              )}
              {renderTextField(
                "No Of Effective",
                imsData
                  ? imsData.noOfEffectiveFTE
                  : fsmsData
                  ? fsmsData.noOfEffectiveFTE
                  : "No Data"
              )}
              {renderTextField(
                "No Of Shifts",
                imsData
                  ? imsData.noOfShifts
                  : fsmsData
                  ? fsmsData.noOfShifts
                  : "No Data"
              )}
              {renderTextField(
                "General Shift",
                imsData
                  ? imsData.shift?.general
                  : fsmsData
                  ? fsmsData.shift?.general
                  : "No Data"
              )}
              {renderTextField(
                "1st Shift",
                imsData
                  ? imsData.shift?.first
                  : fsmsData
                  ? fsmsData.shift?.first
                  : "No Data"
              )}
              {renderTextField(
                "2nd Shift",
                imsData
                  ? imsData.shift?.second
                  : fsmsData
                  ? fsmsData.shift?.second
                  : "No Data"
              )}
              {renderTextField(
                "3rd Shift",
                imsData
                  ? imsData.shift?.third
                  : fsmsData
                  ? fsmsData.shift?.third
                  : "No Data"
              )}
              {renderTextField(
                "Location",
                imsData
                  ? imsData.locationMultiSiteInfo
                  : fsmsData
                  ? fsmsData.locationMultiSiteInfo
                  : "No Data"
              )}
              {renderTextField(
                "HO/CentralOffice",
                imsData
                  ? imsData.HO_or_CentralOffice
                  : fsmsData
                  ? fsmsData.HO_or_CentralOffice
                  : "No Data"
              )}
              {renderTextField(
                "Surveillance Frequency",
                imsData
                  ? imsData.surveillanceFrequency
                  : fsmsData
                  ? fsmsData.surveillanceFrequency
                  : "No Data"
              )}
            </div>

            <h6 className="my-3 fw-bold">
              Cifa :
              {imsData && imsData?.CIFA_FileObjects
                ? imsData.CIFA_FileObjects &&
                  imsData.CIFA_FileObjects.length > 0 &&
                  imsData.CIFA_FileObjects.map((fileObject, index) => (
                    <p>
                      <a
                        key={`ims_${index}`}
                        href={`${SERVER_URL}/${fileObject.uri}`}
                        target="_blank"
                      >
                        {`Cifa(${index + 1})`}
                      </a>
                    </p>
                  ))
                : ""}
              {fsmsData && fsmsData?.CIFA_FileObjects
                ? fsmsData.CIFA_FileObjects &&
                  fsmsData.CIFA_FileObjects.length > 0 &&
                  fsmsData.CIFA_FileObjects.map((fileObject, index) => (
                    <p>
                      <a
                        key={`fsms_${index}`}
                        href={`${SERVER_URL}/${fileObject.uri}`}
                        target="_blank"
                      >
                        {`Cifa(${index + 1}).pdf`}
                      </a>
                    </p>
                  ))
                : ""}
            </h6>
            <h6 className="my-3 fw-bold">
              proposal and agreement -{" "}
              {imsData ? (
                imsData?.proposalAndAgreementFileObject ? (
                  <a
                    href={`${SERVER_URL}/${imsData.proposalAndAgreementFileObject.uri}`}
                    target="_blank"
                  >
                    proposal&agreement .pdf
                  </a>
                ) : (
                  "No Data"
                )
              ) : fsmsData?.proposalAndAgreementFileObject ? (
                <a
                  href={`${SERVER_URL}/${fsmsData.proposalAndAgreementFileObject.uri}`}
                  target="_blank"
                >
                  proposal&agreement .pdf
                </a>
              ) : (
                "No Data"
              )}
            </h6>
          </>
        ) : (
          <>
            <h6 className="my-3 fw-bold">Account Information</h6>
            <div className="d-flex flex-wrap justify-content-start">
              {scheme_session !== "HRA" && (
                <>
                  {renderTextField(
                    "ccode",
                    HRAData ? HRAData.cCode : "No Data"
                  )}
                </>
              )}
              {renderTextField(
                "MainProductionOrService",
                HRAData
                  ? HRAData.mainProductionOrService || HRAData.typeOfServices
                  : "No Data"
              )}
              {renderTextField(
                "AuditType",
                HRAData ? HRAData.auditType : "No Data"
              )}
              {renderTextField("Kob", HRAData ? HRAData.kob : "No Data")}
              {renderTextField(
                "No Of FoodHandlers",
                HRAData ? HRAData.noOfFoodHandlers : "No Data"
              )}
              {renderTextField(
                "FoSTaC_TrainedFoodSafetySupervisors",
                HRAData
                  ? HRAData.FoSTaC_TrainedFoodSafetySupervisors
                  : "No Data"
              )}
              {scheme_session !== "HRA" && (
                <>
                  {renderTextField(
                    "uniqueCertificateNoAndCourseName",
                    HRAData
                      ? HRAData.uniqueCertificateNoAndCourseName
                      : "No Data"
                  )}
                </>
              )}

              {scheme_session === "HRA" && (
                <>
                  {renderTextField(
                    "uniqueCertificateNo",
                    HRAData ? HRAData.uniqueCertificateNo : "No Data"
                  )}

                  {renderTextField(
                    "courseCode",
                    HRAData ? HRAData.courseCode : "No Data"
                  )}

                  {renderTextField(
                    "courseCode",
                    HRAData ? HRAData.courseName : "No Data"
                  )}
                </>
              )}

              {renderTextField(
                "FSSAI_LicenseNumber",
                HRAData ? HRAData.FSSAI_LicenseNumber : "No Data"
              )}
              {renderTextField(
                "ValidityOfLicense",
                HRAData ? HRAData.validityOfLicense : "No Data"
              )}
              {renderTextField(
                "GSTNumber",
                HRAData ? HRAData.GSTNumber : "No Data"
              )}
              {renderTextField("Remark", HRAData ? HRAData.remarks : "No Data")}
              {renderTextField(
                "Total No Of Employees",
                HRAData ? HRAData.totalNoOfEmployees : "No Data"
              )}
              {scheme_session !== "HRA" && (
                <>
                  {renderTextField(
                    "Head Office",
                    HRAData ? HRAData.headOffice : "No Data"
                  )}
                </>
              )}
              {renderTextField(
                "Client Type",
                HRAData ? HRAData?.clientType : "No Data"
              )}
            </div>
            {HRAData?.reviewPointsAndDocuments.map((review, index) => (
              <div key={index} className="mb-3">
                <h6>{review.reviewQuestion}</h6>
                <p className="mb-0">
                  Answer: {review.reviewAnswer ? "Yes" : "No"}
                </p>
                {review.reviewEvidenceDocumentFileObject && (
                  <a
                    href={`${SERVER_URL}/${review.reviewEvidenceDocumentFileObject.uri}`}
                    target="_blank"
                  >
                    {review.reviewEvidenceDocumentFileObject.filename}
                  </a>
                )}
              </div>
            ))}
            <h6 className="my-3 fw-bold">
              Proposal -{" "}
              {HRAData?.proposalFileObject ? (
                <a
                  href={`${SERVER_URL}/${HRAData.proposalFileObject.uri}`}
                  target="_blank"
                >
                  Proposal.pdf
                </a>
              ) : (
                "No Data"
              )}
            </h6>
            <h6 className="my-3 fw-bold">
              Agreement -{" "}
              {HRAData?.agreementFileObject ? (
                <a
                  href={`${SERVER_URL}/${HRAData.agreementFileObject.uri}`}
                  target="_blank"
                >
                  Agreement.pdf
                </a>
              ) : (
                "No Data"
              )}
            </h6>
          </>
        )}

        {SalesNameEmail && (
          <>
            <h6 className="mt-5 fw-bold">Sale Person Details</h6>
            <p>
              <span className="fw-bold"> Name : </span>
              {SalesNameEmail.firstName}
              {SalesNameEmail.lastName}
            </p>
            <p>
              <span className="fw-bold"> Mail : </span>
              {SalesNameEmail.email}
            </p>
          </>
        )}

        <div className="d-flex flex-wrap justify-content-end">
          {scheme_session === "HRA" ? (
            <Button
              variant="contained"
              className="btn-orange"
              onClick={() => {
                handleViewClient();
              }}
            >
              Cancel
            </Button>
          ) : (
            <>
              {isAuthenticated === "Admin" ? (
                ""
              ) : (
                <Button
                  variant="contained"
                  className="btn-orange"
                  onClick={() => {
                    handleViewClient();
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button
                variant="contained"
                className="btn-danger"
                onClick={() => {
                  updateReviewerStatus("rejected");
                }}
              >
                Reject
              </Button>
              <Button
                variant="contained"
                className="btn-linear"
                onClick={() => {
                  updateReviewerStatus("need revision");
                }}
              >
                Need Revision
              </Button>
              <Button
                variant="contained"
                className="btn-green"
                onClick={() => {
                  updateReviewerStatus("approved");
                }}
              >
                Approve
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
}
